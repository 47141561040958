import { find } from "lodash";
import { connect } from "redux-bundler-react";
import { roleAbbrevReverse } from "../../modules/_shared/helper";
import { allowedIds } from "../../modules/_shared/sysadmin-users";

const ApprovalRolesFilter = (props) => {
  const {
    children,
    alt: Alt,
    approvalRolesByLoggedIn,
    domainsItems: domains,
    allowRoles,
    atpmTransferItems,
    profileId,
    useProfile,
    profileApprovalRoles,
    tokenKeyCloakId,
  } = props;

  let items = useProfile ? profileApprovalRoles : approvalRolesByLoggedIn;
  let fullRoleNames = allowRoles.map((role) => roleAbbrevReverse[role]);
  let roleIds = domains
    .filter((item) => fullRoleNames.includes(item.val))
    .map((item) => item.id);

  let found =
    items && items.length > 0 && items.some((r) => roleIds.indexOf(r) >= 0);
  let backupId = domains
    .filter((item) => "Backup ATPM" === item.val)
    .map((item) => item.id);
  let backupRoleFound =
    items && items.length > 0 && items.some((r) => backupId.indexOf(r) >= 0);
  if (allowRoles.includes("*") || allowedIds.includes(tokenKeyCloakId))
    found = true;
  if (
    backupRoleFound &&
    allowRoles.indexOf("BATPM") !== -1 &&
    !!find(atpmTransferItems, (obj) => obj.backup_atpm_id === profileId)
  ) {
    return <>{children}</>;
  } else {
    if (found) return <>{children}</>;
    else if (Alt) return <Alt {...props} />;
    else return null;
  }
};

export default connect(
  "selectApprovalRolesByLoggedIn",
  "selectDomainsItems",
  "selectApprovalRolesIsLoading",
  "selectAtpmTransferItems",
  "selectProfileId",
  "selectProfileApprovalRoles",
  "selectTokenKeyCloakId",
  ApprovalRolesFilter
);
