import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import Grid from "../../_shared/grid";
import { customComparator } from "../../_shared/helper";

export default connect(
  "selectMissionsReportItems",
  "selectOrgsByRoute",
  "selectMissionsReportIsLoading",
  ({
    missionsReportItems: data,
    missionsReportIsLoading,
    signatureRangeObject,
    approverObject,
    sorted,
    setSorted,
  }) => {
    const signatureComparator = (valA, valB, __, __B, isInverted) => {
      if (valA && valB)
        return customComparator(
          decryptSignature(valA).username,
          decryptSignature(valB).username
        );
      else if (valA && !valB) return isInverted ? 1 : -1;
      else if (!valA && valB) return isInverted ? -1 : 1;
      else return isInverted ? 1 : -1;
    };
    useEffect(() => {
      let temp = data;
      if (
        signatureRangeObject &&
        Object.keys(signatureRangeObject).length > 0
      ) {
        Object.keys(signatureRangeObject).forEach((role) => {
          const { from, to } = signatureRangeObject[role];
          if (!from && !to) return;
          else
            temp = temp.filter((item) => {
              if (item[role]) {
                let itemSignature = new Date(decryptSignature(item[role]).date);
                return from <= itemSignature && itemSignature <= to;
              } else return false;
            });
        });
      }
      // filter by approver name based on their role.. combining all name filters together
      if (approverObject && Object.keys(approverObject).length > 0) {
        Object.keys(approverObject).forEach((role) => {
          if (approverObject[role] === "") return;
          else
            temp = temp.filter((mission) => {
              return mission[role]
                ? decryptSignature(mission[role])
                    .username.toUpperCase()
                    .includes(approverObject[role].toUpperCase())
                : false;
            });
        });
      }
      setSorted(temp);
    }, [data, signatureRangeObject, approverObject]);

    const decryptSignature = (sig) => JSON.parse(atob(sig.split(".")[0]));
    const config = {
      columnDefs: [
        {
          resizable: true,
          sortable: true,
          headerName: "Organization",
          field: "org_name",
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Name",
          field: "name",
          cellRenderer: (params) => (
            <a
              href={`/${
                params.data.org_name && params.data.org_name.toLowerCase()
              }/mission/${params.data.slug}`}
            >
              {params.data.name}
            </a>
          ),
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Description",
          field: "description",
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Start Date",
          field: "date_start",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const d = new Date(cell.value);
            return d.toLocaleDateString();
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "End Date",
          field: "date_end",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const d = new Date(cell.value);
            return d.toLocaleDateString();
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Last Flight Date",
          field: "last_flight_date",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const d = new Date(cell.value);
            return d.toLocaleDateString();
          },
        },
        // {
        //   resizable: true,
        //   sortable: true,
        //   headerName: "Authority",
        //   field: "authority",
        // },
        {
          resizable: true,
          sortable: true,
          headerName: "Max Altitude",
          field: "max_alt",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Archived",
          field: "archived",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Flight Category",
          field: "flight_category",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            return cell.value.join(", ");
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Mission Type",
          field: "mission_type",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            return cell.value.join(", ");
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Overall Mission Task",
          field: "overall_mission_task",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            return cell.value.join(", ");
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Flight Mode",
          field: "flight_mode",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            return cell.value.join(", ");
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Approval Status",
          field: "approval_status",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Schedule Status",
          field: "schedule_status",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Debrief Status",
          field: "debrief_status",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Mission Status",
          field: "mission_status",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Flight Minutes",
          field: "total_flight_time",
          valueFormatter: (cell) => {
            if (!cell.value) return 0;
            else return cell.value.toFixed(2);
          },
          comparator: (valueA, valueB) => valueA - valueB,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Flight Count",
          field: "flight_count",
          comparator: (valueA, valueB) => valueA - valueB,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Prepared By",
          field: "plan_sig",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const sigInfo = decryptSignature(cell.value);
            return sigInfo.username;
          },
          comparator: signatureComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "MBO Signature",
          field: "mbo",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const sigInfo = decryptSignature(cell.value);
            return sigInfo.username;
          },
          comparator: signatureComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "ATPM Signature",
          field: "atpm",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const sigInfo = decryptSignature(cell.value);
            return sigInfo.username;
          },
          comparator: signatureComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Quality Review",
          field: "review_sig",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const sigInfo = decryptSignature(cell.value);
            return sigInfo.username;
          },
          comparator: signatureComparator,
        },
        // {
        //   resizable: true,
        //   sortable: true,
        //   headerName: "MAA Signature",
        //   field: "maa",
        //   valueFormatter: (cell) => {
        //     if (!cell.value) return null;
        //     const sigInfo = decryptSignature(cell.value);
        //     return sigInfo.username;
        //   },
        //   comparator: signatureComparator,
        // },
        {
          resizable: true,
          sortable: true,
          headerName: "Adjudications Pre Approval",
          field: "pre_count",
          comparator: (valueA, valueB) => valueA - valueB,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Adjudications Post Approval",
          field: "post_count",
          comparator: (valueA, valueB) => valueA - valueB,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Airspace Classification",
          field: "airspace_classification",
        },
      ],
    };
    return (
      <Grid
        isLoading={missionsReportIsLoading}
        config={config}
        data={sorted}
        style={{ height: `${window.innerHeight * 0.8}px`, width: "100%" }}
      />
    );
  }
);
